.App {
  min-width: max-content;
  justify-content: space-between;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.konvajs-content{
  background: linear-gradient(#000, #000) no-repeat center/1px 100%;
}

.MenuContainer{
  margin-right: 20px;
  background-color: #E6E1E1;
}
ul{
  display: flex;
  flex-direction: column;
  list-style-type: none;
}
li{
  min-width: 200px;
  margin:5px;
  padding: 2px;
  border-radius: 10px;
  background-color: white;
  cursor: pointer;
  text-align: center;
}
li:hover{
  background-color: #4565f4;
}

.App{
  display: flex;
}

.TextListMenu{
  font-weight: bold;
}
li:hover .TextListMenu{
  color:white;
}
.back canvas{
  background-size: 10px 10px !important;
  background-image:
  linear-gradient(to right, #c5c5c5 1px, transparent 1px),
  linear-gradient(to bottom, #c5c5c5 1px, transparent 1px) !important
}

.back{
  background-color: white;
}

.btnPDF{
  border-radius: 10px;
  background-color: #4565f4;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  cursor: pointer;
  width: 150px;
  height: 54px;
  margin-top: 50px;
}
.btnSpace{
  margin-left: 15px;
  margin-right: 15px;
}

.repeatText{
  display: flex;
  justify-content: center;
  align-items: center;
}